.chat-window {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    z-index: 1000;
    color: black;
    width: 50vw;
    height: 90vh;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.chat-title {
  width: 100%;
  height: 12%;
  background-color: rgb(53, 53, 53);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 200;
}

.chat-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.chat-body {
  background-color: white;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  overflow: scroll;
  padding: 1em;
  height: 100%;
  padding-bottom: 1.5em;
}

.chat-body > :first-child {
  margin-top: auto !important;
}

.chat-body .msg-container {
  max-width: 40%;
  /* border: 1px solid red; */
}

.waiting {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 60%;
  font-size: 1.6rem;
  margin-top: 5rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 200;
}

.waiting div {
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
  font-size: 1rem;
  text-align: center;
  line-height: 1.5rem;
}

.waiting div p {
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
  font-size: 1rem;
  text-align: center;
}

.chat-input {
  width: 100%;
  height: 8%;
  display: flex;
}

.chat-input input {
  width: 85%;
  height: 100%;
  font-size: 1rem;
  font-weight: 300;
  font-family: 'Noto Sans JP', sans-serif;
  padding: 1rem;
  border: 10px solid rgb(186, 185, 185);
}

.chat-input input:disabled {
  border: 10px solid rgb(215, 215, 215);
}

.chat-input input:focus {
    outline: none;
}

.chat-input .send-message {
  width: 16%;
  height: 100%;
  background-color: rgb(74, 118, 231);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
}

.chat-input .send-message:hover {
  cursor: pointer;
}

.chat-input button {
  border: none;
}

.chat-input button:disabled {
  background-color: rgb(177, 192, 228);
}

.chat-input button:disabled:hover {
  cursor: not-allowed !important;
}

.close-chat {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.3rem;
  color: white;
}

.close-chat:hover {
  cursor: pointer;
}

.no-answer {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  font-size: 1.1rem;
  color: rgb(131, 129, 129);
  height: 100%;
  flex-direction: column;
}

.no-answer .sorry {
  font-size: 3rem;
  color: rgb(28, 28, 28);
}

.sad-face {
  color:rgb(225, 225, 225);
  width: 200px;
  height: 200px;
  align-self: center;
}