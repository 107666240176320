.social-media{
  height: 5vh;
  display: flex;
  justify-content: space-evenly;
  padding: 10% 25%;
  background-color: white;
  flex-wrap: wrap;
}

.social-media a {
  text-decoration: none !important;
  position: relative;
  display: flex;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 3px rgba(0,0,0,0.3);
  transition: 0.6s;
}

.social-media .wechat:hover {
  background-color: #09b83e;
}

.social-media .facebook:hover {
  background-color: #3b5999;
}

.social-media .instagram:hover {
  background-color: #e4405f;
}

.social-media .twitter:hover {
  background-color: #55acee;
}

.social-icon {
  color: rgb(31, 30, 30);
  font-size: 1.4rem;
}

.social-media a:hover {
  transform: translate(0, -10px);
}

.social-media a:hover .social-icon {
  color: white;
}

.footer-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 3rem 0;
  background-color: rgb(25, 25, 25);
  color: white;
  flex-direction: column;
  padding-top: 4rem;
}

.copyright {
  border-top: 1px solid rgb(206, 206, 206);
  padding-top: 1.5rem;
  width: 80%;
  display: flex;
  justify-content: center;
  font-family: 'Brutal';
  font-size: 0.9rem;
}

.terms {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  text-transform: Uppercase;
  font-size: 0.9rem;
  font-weight: 50;
}

.terms li {
  text-decoration: none;
  list-style-type: none;
}

.link {
  padding: 0 1rem;
  text-decoration: none;
  list-style-type: none;
  color: white;
}

.terms .link:hover{
  text-decoration: underline;
  cursor: pointer;
  color: rgb(141, 172, 250);
}

.privacy-policy {
  border-right: solid 1px white;
}

.terms-condition {
  border-right: solid 1px white;
}

.dark-footer {
  background-color: rgb(25, 25, 25);
}

.dark-footer a {
  box-shadow: 0 2px 3px rgba(0,0,0,0.3);
  background-color: white;
}

@media only screen and (max-width: 768px) {
  .terms {
    font-size: 0.66rem;
  }
  
  .terms li{
    padding: 0;
  }

  .copyright { 
    font-size: 0.75rem;
  }

  .social-media .wechat {
    background-color: #09b83e;
  }
  
  .social-media .facebook {
    background-color: #3b5999;
  }
  
  .social-media .instagram {
    background-color: #e4405f;
  }
  
  .social-media .twitter {
    background-color: #55acee;
  }
  
  .social-icon {
    color: white;
  }

  .social-media{
    padding: 15%;
  }
}

@media only screen and (max-width: 375px) {
  .terms {
    font-size: 0.6rem;
    font-weight: 10;
  }
  .link {
    padding: 0rem 8px;
  }
}