/* .promo-container {
  position: relative;
  height: 90vh;
  z-index: 999;
}

.black-cover {
  display: flex;
  flex-direction: column;
  width: 50vw;
  height: 90vh;
  padding: 3rem;
  background-color: black;
  position: absolute;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
}

.left-side {
  display: flex;
  flex-direction: column;
  width: 60vw;
  min-height: 90vh;
  height: auto;
  padding: 3rem;
  background-color: black;
  mix-blend-mode: screen;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 4;
  padding-right: 5rem;
  color: white;
  justify-content: space-around;
  align-items: center;
  padding-top: 15vh;
  padding-right: 15vw;
}

.left-title {
  font-size: 3.5rem;
  color: white;
  align-self: center;
}

.left-description {
  font-size: 0.9rem;
  color: #2997ff;
}

.right-side {
  display: flex;
  flex-direction: column;
  width: 60vw;
  min-height: 90vh;
  height: auto;
  padding: 0rem 3rem;
  padding-bottom: 2rem;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: 1;
  padding-top: 15vh;
  padding-left: 15vw;
  align-items: center;
  justify-content: space-around;
}

.right-description {
  font-size: 3rem;
  color: rgb(12, 12, 12);
  width: 1500px;
  text-align: right;
}

.volpone-house {
  width: 18rem;
  position: absolute;
  left: 49.4vw;
  top: 55vh;
  mix-blend-mode: difference;
  z-index: 3;
  transform: translate(-50%, -50%);
}




@media screen and (max-width: 968px) { 
  .promo-container {
    position: relative;
    height: 60vh;
  }

  .left-side {
    width: 100vw;
    padding: 3rem 2rem;
    position: absolute;
    height: 90vh;
    padding-bottom: 5rem;
  }

  .black-cover {
    width: 100vw;
    padding: 0;
    height: 88vh;
  }

  .volpone-house {
    width: 20rem;
    position: absolute;
    left: 50vw;
    top: 88vh;
    mix-blend-mode: difference;
    z-index: 3;
    transform: translate(-50%, -50%);
  }

  .right-side {
    width: 100vw;
    margin: 0;
    position: absolute;
    top: 80vh;
    height: 90vh;
  }

  .promo-footer {
    position: absolute;
    bottom: -130%;
    left: 0;
    right: 0;
  }
} */


.promotion-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* overflow-x: auto; */
  width: fit-content;
  height: 86vh;
  overflow: hidden
}

.somebox {
  width: 600px;
  height: 300px;
  background-color: rgb(81, 81, 223);
  border: 1px solid red;
}