.image-gallery {
  height: 70vh;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr), 1.5fr;
  grid-template-rows: 3fr 2fr;
  grid-gap: 1rem;
  grid-template-areas: 
      "img-1 img-2 img-2 img-2 img-3"
      "img-4 img-4 img-5 img-5 img-3";
  border-bottom: 1px solid rgb(191, 191, 191);
  position: relative;
  overflow: hidden;
  top: -6em;
}

.faq-title-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom left, transparent, transparent, transparent, rgba(0,0,0,0.6), #000000);
  display: flex;
  align-items: flex-end;
  padding: 3rem;
  animation: fade 4s ease;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.85;
  }
}

.faq-title-wrap .text{
  color: white;
  font-size: 3rem;
  animation: 1s text-rise ease-out;
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
}

.img-1 {
  grid-area: img-1;
  background-image: url("./image_gallery/img-13.jpg");
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  animation-name: down;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-delay: 1s;
  transform: translateY(-100%);
}

.img-2 {
  grid-area: img-2;
  background-image: url("./image_gallery/img-2.jpeg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  animation-name: right;
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-delay: 0.1s;
  transform: translateX(200%);
}

.img-3 {
  position: relative;
  grid-area: img-3;
  background-image: url("./image_gallery/img-12.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  animation-name: up;
  animation-duration: 1.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.8s;
  transform: translateY(100%);
}

.img-4 {
  grid-area: img-4;
  background-image: url("./image_gallery/img-5.jpeg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  animation-name: right;
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-delay: 0.1s;
  transform: translateX(200%);
}

.img-5 {
  grid-area: img-5;
  background-image: url("./image_gallery/img-11.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  animation-name: left;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-delay: 0.1s;
  transform: translateX(-200%);
}

@keyframes text-rise{
  0% {
    transform: translateY(200%);
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes up {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes down {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes right {
  0% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes left {
  0% {
    transform: translateX(200%);
  }
  100% {
    transform: translateX(0);
  }
}

.volpone-support {
  background-color: white;
  height: 13vh;
  vertical-align: center;
  padding: 2rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.volpone-support .support {
  position: relative;
  top: 30%;
  color: rgb(128, 126, 126, 0.8);
  font-size: 0.8rem;
  height: fit-content;
}

.volpone-support img {
  height: 100%;
}


.info-section {
  background-color: white;
  width: 100%;
  height: 100%;
  padding: 6rem 20%;
  padding-bottom: 8rem;
}

.info-container {
  display: flex;
  width: 100%;
  padding: 3.5rem 2.8rem;
  background-color: white;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.2);
  align-items: center;
  border-radius: 0.5rem;
  color: rgb(55, 55, 55);
  position: relative;
}

.info-img {
  min-width: 22rem;
  max-width: 22rem;
  height: 19rem;
  border-radius: 0.3rem;
  position: absolute;
  left: -7%;
}

.info-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  border-radius: 0.5rem;
  position: absolute;
  transition: opacity 0.4s linear;
}

.hidden {
  opacity: 0 !important;
}

/* #magnify-glass {
  position: absolute;
  bottom: 0.6rem;
  right: 0.6rem;
  z-index: 1;
  transition: 0.3s ease;
  opacity: 0.5;
} */

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.info-img::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /* background: rgb(169, 169, 169, 0.2); */
}

.info-text {
  text-indent: 50px;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 0.9rem;
}

.info-description {
  margin-left: 20rem;
}

.signature {
  margin-top: 2rem;
  text-align: right;
  font-size: 1.2rem;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
}

.location {
  font-size: 0.8rem;
  font-weight: 400;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: italic;
  color: gray;
}

.info-title {
  margin-bottom: 1.5rem;
  text-align: center;
  color: rgb(55, 55, 55);
  padding: 5px 0;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
}

.product-quality-container {
  height: 60vh;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.product-quality-wrapper {
  height: 100%;
  width: 100%;
  background-image: url("./image_gallery/advancetech.jpg");
  background-position: center;
  background-size: cover;
  transition: all .5s;
}

.product-quality-container:hover .product-quality-wrapper,
.product-quality-container:focus .product-quality-wrapper {
  transform: scale(1.2);
}

.product-quality-description {
  position: absolute;
  left: 0;
  top: 0;
  height: auto;
  width: 40rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: rgb(49, 102, 182);
  font-size: 2rem;
  padding-top: 6rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  align-items: center;
}

.more-details {
  /* color: black;
  font-size: 1rem;
  width: 80%; 
  text-align: center; 
  border-bottom: 1px solid #000; 
  line-height: 0.1em;
  margin: 1rem 0; */
  width: 60%; 
  text-align: center; 
  border-top: 2px solid #000; 
  line-height: 1rem;
  margin: 10px 0; 
}

.more-details span {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem; 
  /* font-family: 'Roboto', sans-serif;
  font-weight: 100; */
  font-weight: 100;
  font-size: 1.3rem;
  background-color: transparent;
  color: rgb(49, 102, 182, 0.7);
}

@media (hover: hover) and (pointer: fine) {
  .more-details span:hover{
    color: rgb(49, 102, 182);
    cursor: pointer;
  }

  .accordion-container:hover {
    box-shadow: 0 10px 20px rgba(0,0,0,0.25);
  }

  .accordion-container:hover .question {
    color: black;
  }
}

.plus-square { 
  margin-right: 8px;
  font-size: 0.9rem;
}


.product-quality-wrap {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}

.product-quality-info {
  width: 32rem;
  height: auto;
  border-left: 1.5px solid rgb(157, 157, 157);
  border-right: 1.5px solid rgb(157, 157, 157);
  font-size: 1rem;
  color: rgb(60, 60, 60);
  line-height: 1.5rem;
  padding: 1rem;
  background-color: transparent;
  font-weight: 100;
  font-family: 'Roboto', sans-serif;
  max-height: 15rem;
  transition: max-height 1s ease;
  overflow: hidden;
  position: absolute;
  top: 59%;
}

.hidden.product-quality-info {
  max-height: 0;
  transition: max-height 1s ease;
}

.faq-section {
  background-color: white;
  padding-top: 1rem;
  padding-bottom: 8rem;
  color: rgb(40, 39, 39);
  border-top: 1px solid rgb(197, 195, 195);
}


.faq-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin: auto;
  padding: 3.2rem 0;
}

.category {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  padding: 1rem 0;
  width: 90%;
  background-color: white;
  text-align: center;
  color: rgb(88, 135, 206);
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
}

.accordion-container {
  width: 90%;
  margin: 0.4rem;
  margin-bottom: 0.5rem;
  box-shadow: 0 2px 5px rgba(0,0,0,0.22), 0 1px 3px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.accordion-show {
  box-shadow: 0 10px 20px rgba(0,0,0,0.25);
}

.accordion-container .question {
  display: flex;
  justify-content: space-between;
  padding: 1.2em 1.5em;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 1.1rem;
  color: rgba(33, 33, 33, 0.6)
}

.question .text {
  padding-right: 3rem;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 1rem;
  font-weight: 500;
}

.answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease;
  background-color: white;
}

.show-question {
  color: rgba(33, 33, 33) !important;
}

.answer-text{
  padding: 1rem;
  margin: 0 0.7rem 1rem 0.7rem;
  background-color: rgb(184, 184, 184, 0.25);
  color: rgba(33, 33, 33, 0.9);
  border-left: 6px solid rgb(88, 135, 206);
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 0.85rem;
  font-weight: 300;
}

.amazon-return-policy {
  text-decoration: none;
  color:rgb(62, 62, 245);
}

.amazon-return-policy:visited {color:rgb(62, 62, 245);}

/* services */

.services {
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 1rem;
  margin: 1rem 0;
  margin-top: 5rem;
  color: rgb(36, 36, 36);
  flex-wrap: wrap;
}

.service-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  margin-bottom: 4rem;
  flex-direction: column;
  position: relative;
  padding: 2rem 0rem;
  min-width: 230px;
  width: 2em;
  border-radius: 12px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
}

.service-box#hide-outter-l {
  left: -100%;
  transition: 2s
}

.service-box#hide-inner-l {
  left: -100%;
  transition: 2s
}

.service-box#reveal-inner-l {
  left: 0;
  transition: 2s;
}

.service-box#reveal-outter-l {
  left: 0;
  transition: 2s;
}

.service-box#hide-inner-r {
  right: -100%;
  transition: 2s
}

.service-box#hide-outter-r {
  right: -100%;
  transition: 2s
}

.service-box#reveal-inner-r {
  right: 0;
  transition: 2s;
}

.service-box#reveal-outter-r {
  right: 0;
  transition: 2s;
}

.fade-left {
  transform: translateX(-200%);
}

.fade-right {
  transform: translateX(200%);
}

.service-icon {
  font-size: 4.5rem;
  color: rgb(110, 110, 115);
  position: relative;
  top: 0rem;
}

.service-icon.stars {
  top: -1rem;
  width: 100%;
  justify-content: center;
  display: flex;
  vertical-align: center;
  padding: 1.3rem 0;
}

.star-icon {
  font-size: 2rem;
  color: rgb(110, 110, 115);
}

.core-values-container { 
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 3rem 0 1rem 0;
  /* background-color: rgb(244, 244, 244); */
  padding: 0 20%;
}

.core-values-container h5{
  font-size: 1rem;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  color: rgba(18, 18, 18, 0.5);
  margin: 0.8rem 0 ;
}

.core-values-container h3{
  font-size: 2rem;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  color: rgba(12, 12, 12);
  margin: 0.8rem 0;
  text-align: center;
}

.core-values-container p{
  font-size: 1.2rem;
  font-weight: 300;
  font-family: 'Roboto', sans-serif;
  color: rgba(12, 12, 12);
  margin: 0.8rem 0;
  text-align: center;
  line-height: 1.5rem;
}

.service-box .text-wrap {
  padding: 1rem 0.5rem;
  margin-top: 1rem;
}

.service-box .text-wrap p {
  width: 100%;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 0.75rem;
  margin-top: 1rem;
}

.service-box .text-wrap h1 {
  font-size: 1.2rem;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  text-align: center;
}

.service-box .text-wrap h2 {
  font-size: 0.9rem;
  font-family: 'Ubuntu', sans-serif;
  color: #8A897C;
  font-weight: 500;
  margin-top: 1.5rem;
  text-align: center;
}


@media only screen and (max-width: 768px) {
  .image-gallery {
    grid-gap: 0px;
  }

  .faq-title-wrap .text{
    font-size: 2.5rem;
  }

  .faq-container {
    width: 100%;
  }

  .accordion-container {
    width: 90%;
  }

  .question .text {
    padding-right: 10px;
  }

  .faq-title{
    padding: 2.5rem 1rem;
  }

  .volpone-support {
    height: 14vh;
    width: 100%;
  }
  
  .volpone-support .support {
    font-size: 0.7rem;
  }
  
  .adhesive-answer {
    padding: 3rem 1.5rem;
    border-bottom: 1px solid rgb(165, 163, 163);
    font-size: 0.8rem !important;
  }

  .adhesive-install-wrapper{
    padding: 8rem 0.5rem;
    padding-bottom: 3rem;
  }

  .answer-text {
    font-size: 0.75rem;
  }

  .adhesive-image-note{
    font-size: 0.6rem;
  }

  .product-quality-description {
    position: absolute;
    left: 0;
    top: -50%;
    right: 0;
    bottom: 0;
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: rgb(49, 102, 182);
    font-size: 1.5rem;
    padding-top: 0;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    align-items: center;
  }

  .product-quality-wrapper {
    opacity: 0.6;
  }

  .product-quality-info {
    width: 90%;
    font-size: 0.8rem;
  }

}

@media only screen and (max-width: 1024px) {
  .adhesive-install-container{
    padding: 3rem 1rem;
  }
  
  .adhesive-install-wrapper{
    padding: 3rem 0.5rem;
  }


  .wall-container {
    text-align: center;
    margin: 0.5rem;
    transition: all 0.3s ease;
  }
  
  .info-img {
    top: -15%;
    left: 50%;
    transform: translateX(-50%);
  }

  .info-description {
    margin-left: 0;
    margin-top: 12rem;
  }

  .info-section {
    padding-top: 13rem;
  }

  .install-title {
    margin: 0 1.5rem;
    top: -3rem;
    left: 1rem;
    padding: 2rem 2rem;
    font-size: 1.2rem;
  }

  .wall-container:nth-child(even) {
    transform: translateY(
      0px);
  }

  .services {
    margin: 4rem 1%;
  }
  
}

@media only screen and (max-width: 600px) {
  .info-section {
    padding: 2rem 6%;
    padding-top: 12rem;
  }

  .info-img {
    min-width: 18rem;
    max-width: 18rem;
    height: 18rem;
  }

  .info-container {
    padding: 3rem 1.3rem;
  }

  .core-values-container { 
    margin: 7rem 0 1rem 0;
    padding: 0 10%;
  }
  
  .core-values-container h5{
    font-size: 0.8rem;
    margin: 0.8rem 0 ;
  }
  
  .core-values-container h3{
    font-size: 1.6rem;
    margin: 0.8rem 0;
  }
  
  .core-values-container p{
    font-size: 1rem;
  }

  .install-title {
    padding: 1.2rem 4rem;
  }

  .adhesive-mounting {
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 1200px) {
  .product-quality-wrapper {

  }

  .product-quality-info {
    border-left: 1.5px solid white;
    border-right: 1.5px solid white;
    color: white;
    background-color: rgba(133, 131, 131, 0.8);
  }
}

@media only screen and (max-width: 600px) {
  .service-box#hide-outter-l {
    left: 0;
  }
  
  .service-box#hide-inner-l {
    left: 0;
  }
  
  .service-box#hide-inner-r {
    right: 0;
  }
  
  .service-box#hide-outter-r {
    right: 0;
  }
}