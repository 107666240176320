.call-window {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 250px;
  /* border: 1px solid rgb(150, 150, 150); */
  border-radius: 5px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  background-color: rgba(255, 255, 255);
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.call-window div {
  font-family: 'Robot', sans-serif;
  font-weight: 500;
  font-size: 1.3rem;
}

.call-window button {
  padding: 5px 8px;
  color: white;
  background-color: rgb(60, 95, 155, 0.7);
  outline: none;
  border: none;
  border-radius: 3px;
}

.call-window button:hover {
  background-color: rgb(60, 95, 155)
}