
.products-container {
  background-image: url("./bathroombg.jpeg");
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: fixed;
  z-index: -3;
  animation: 0.8s specialEffect ease;
  top: 0;
  margin: 0;
}

@keyframes specialEffect {
  0% {
    transform: scale(2.5);
  }

  100% {
    transform: scale(1);
  }
}

.curtain {
  height: 100vh;
  width: 100%;
  position: absolute;
  z-index: -2;
  background-color: black;
  opacity: 0;
}

.clear-window {
  height: 100vh;
  width: 100%;
  /* position: relative;
  z-index: -99; */
}

.product-title-container {
  color: white;
  position: fixed;
  z-index: -2;
  left: 23%;
  top: 56%;
  font-size: 4.5em;
  /* transition: all 0.5s ease-in; */
}

.product-title.sub {
  font-size: 0.35em;
}

.line-wrapper{
  overflow: hidden;
}

.products {
  height: 100%;
  width: 100%;
  background-color: white;
}

.product-title-2 {
  color: rgb(26, 26, 26);
  font-size: 2.6em;
  position: fixed;
  z-index: -2;
  right: 16%;
  top: 35%;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 300;
  animation: slide-from-top 2.3s;
  /* animation-delay: 1s; */
  transform: rotate(270deg);
}

.product-nav {
  display: flex;
  list-style-type: none;
  padding-bottom: 3em;
  padding-top: 4.5em;
  min-width: 350px;
  max-width: 650px;
  justify-content: space-between;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
  width: 450px;
}

.product-nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 8rem;
  padding-top: 2rem;
}

.shipping{
  display: flex;
  align-items: center;
  padding-top: 2rem;
  padding-left: 2rem;
}

.prime-logo {
  width: 5rem;
}

.product-nav li {
  /* text-decoration: none; */
  cursor: default;
  font-family: 'Rubik', sans-serif;
  font-weight: 300;
  font-size: 1rem;
  padding: 10px 14px;
  border: 1px solid rgb(67, 66, 66, 0.8);
  display: flex;
  text-transform: uppercase;
  transition: background-color 0.2s ease;
}

.product-nav-selected {
  background-color: rgb(67, 66, 66, 0.8);
  color: white;
}

.product-nav li:hover {
  background-color: rgb(18, 18, 18, 0.95);
  color: white;
}

.product-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  border-top: 2px solid rgb(142, 142, 142);
  padding-top: 12vh;
  margin: 0 3vw;
  padding-bottom: 10vh;
  position: relative;
  border-bottom: 1px solid rgb(142, 142, 142);
}

.time-stamp {
  font-size: 0.75rem;
  text-align: right;
  position: absolute;
  top: 0%;
  justify-self: flex-start;
  width: 100%;
  padding-top: 0.5rem;
  color: rgb(143, 141, 141);
}

.images-note {
  text-align: right;
  width: 100%;
  font-size: 0.8rem;
  margin-top: 2rem;
  color: rgb(189, 189, 189);
}

.scrollTop {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 110vh;
  right: 40px;
  font-size: 2em;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: rgb(70, 70, 70, 0.5);
  color: white;
  z-index: 99;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
}

.scrollTop.active{
  visibility: visible;
  opacity: 1;
  bottom: 40px;
}

.scrollTop:hover{
  background: rgb(70, 70, 70, 1);
}



@keyframes slide-from-top {
  from {
    transform: rotate(270deg) translateX(100%);
  }

  to {
    transform: rotate(270deg) translateX(0%)
  }
}

@media only screen and (max-width: 960px) {
  .navbar-products {
    background-color: rgb(33, 33, 33);
  }
}

@media only screen and (max-width: 1024px) {
  .product-title-container {
    top: 75%;
    right: 55px;
    font-size: 3em;
    /* transition: all 0.5s ease-in; */
  }

  .product-title-2 { 
    right: 25px;
    top: 250px;
    color: black;
    font-size: 1.7em;
    font-weight: 400;
  }

  .product-nav-container { 
    justify-content: center;
    margin: 0;
  }
  .product-nav {
    padding: 3.5em 0.5em; 
  }
  .product-nav li{
    font-size: 15px;
  }

  .shipping{
    padding-top: 0;
  }
}
