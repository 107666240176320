.moodal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem 3rem;
  background-color: white;
  z-index: 1000;
  color: black;
  width: 50vw;
  height: 90vh;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 900;
}

.moodal h1 {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgb(171, 171, 171);
  width: 100%;
  text-align: center;
}

.moodal-buttons {
  display: flex;
  width: 70%;
  justify-content: space-between;
}

.moodal-buttons #start{
  font-size: 1rem;
  padding: 0.5rem 3rem;
  color: white;
  background-color: rgb(11, 162, 54, 0.7);
  border: none;
  border-radius: 5px;
}

.moodal-buttons #cancel{
  font-size: 1rem;
  padding: 0.5rem 3rem;
  color: white;
  background-color: rgb(162, 72, 65, 0.7);
  border: none;
  border-radius: 5px;
}

.moodal p {
  font-family: "Roboto", sans-serif;
  font-size: 0.75rem;
  text-align: left;
  line-height: 1.2rem;
}

.moodal .text-wrap {
  width: 70%;
  border: 1px solid rgb(130, 130, 130);
  padding: 1rem;
  font-size: 0.8rem;
  font-family:'Robot', sans-serif;
  font-weight: 100;
  line-height: 1.1rem;
}

.moodal label {
  font-size: 1.2rem;
  font-family: "Roboto", sans-serif;
  margin: 0 1rem;
}

.moodal input {
  width: 16rem;
  height: 2rem;
  padding: 0 5px;
}

.moodal input[type="text"]
{
    font-size: 0.9rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
}

.moodal input:focus {
  outline:none;
}

.error-message div{
  color: rgb(165, 70, 70);
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  line-height: 1.5rem;
  height: 1.5rem;
  width: 100%;
}

@media (hover: hover) and (pointer: fine) {
  .moodal-buttons #start:hover{
    background-color: rgb(11, 162, 54);
  }

  .moodal-buttons #cancel:hover{
    background-color: rgb(162, 72, 65);
  }
}