.logo{
  filter: brightness(0) invert(1);
  width: 6.5em;
}

.navbar {
  /* background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%); */
  /* background: lightgray; */
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 800;
  transition: height 0.5s ease;
}

.navbar.navbar-shrink {
  margin-bottom: 25px !important;
  height: 75px;
  opacity: 0.6;
  transition: opacity 0.3s ease-in-out, height 0.5s ease;
}

.navbar:hover {
  opacity: 1;
}

.navbar-dark{
  background-color: rgb(33, 33, 33, 0.7);
}

.navbar-products.navbar-shrink{
  background-color: rgb(33, 33, 33);
  margin-bottom: 25px !important;
  height: 75px;
  opacity: 0.6;
  transition: opacity 0.3s ease-in-out, height 0.5s ease;
}

.navbar-products:hover {
  opacity: 1;
}

.navbar-light{
  background: transparent;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  /* max-width: 1500px; */
  width: 100%;
}

.navbar-logo {
  display: flex;
  color: #fff;
  /* justify-self: flex-start !important; */
  margin-left: 30px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  align-items: center;
}

.navbar-installation {
  position: fixed;
  width: 100vw;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  /* justify-content: end; */
  margin-right: 20px;
}

.navbar-left{
  display: flex;
  align-items: center;
  margin-right: 30px;
}

.nav-item {
  height: 80px;
}

.navbar-shrink .nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 2.6rem 1rem;
  height: 100%;
}

.navbar-installation .nav-links {
  color: rgb(33, 33, 33);
}

.navbar-installation .nav-links:hover {
  border-bottom: 5px solid rgb(33, 33, 33);
  transition: border-bottom 0.2s ease-out;
  position: relative;
}

.navbar-installation .btn--outline:hover {
  color: white;
  background-color: rgb(33, 33, 33, 0.9);
}

.navbar-installation .logo{
  filter: brightness(40%);
}

.nav-links:hover {
  border-bottom: 5px solid #fff;
  transition: border-bottom 0.2s ease-out;
  position: relative;
}

.navbar-shrink .nav-links:hover {
  border-bottom: none;
}

.navbar-shrink .nav-links:hover:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 87%;
  border-bottom: 4px solid #fff;
}

.navbar-installation.navbar-shrink .nav-links:hover:after {
  border-bottom: 4px solid black;
  transition: border-bottom 1s ease-out;
}


.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  /* .navbar-container{
    vertical-align: middle;
  } */
  .navbar-logo{
   /* align-self: center; */
   display: block;
   position: absolute;
   /* top: -30px; */
   height: 30px;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 0;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242222 !important;
    left: 0;
    opacity: 1 !important;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
}

@media screen and (max-height: 570px) {
  .logo{
    width: 5em;
  }
}