* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.dashboard {
  display: flex;
  margin: auto;
  border: 1px solid rgb(53, 52, 52);
  border-bottom: none;
  width: auto;
  height: 85vh;
}

.dashboard-left {
  height: 100%;
  width: 25%;
  /* border: 2px solid rgb(45, 107, 215); */
  border: 1px solid rgb(123, 123, 123);
}

.connected {
  height: 60%;
  overflow-y: auto;
}

.missed {
  overflow-y: auto;
  height: 40%;
}

.connected::-webkit-scrollbar {
  display: none;
}

.missed::-webkit-scrollbar {
  display: none;
}

.person {
  padding: 0.5rem;
  border-bottom: 1px solid rgb(189, 189, 189);
  font-size: 0.9rem;
  line-height: 2em;
  position: relative;
  padding-right: 2rem;
  overflow-wrap: break-word;
  border: 1px solid transparent;
  border-bottom: 1px solid lightgray;
}

.delete-bin {
  position: absolute;
  right: 10px;
  top: 40%;
  color: rgb(160, 158, 158);
}

.delete-bin:hover {
  color: rgb(28, 28, 28);
}

.connected .person {
  background-color: rgb(168, 195, 232, 0.4);
  /* transition: all 0.5s ease; */
}

.connected .person.disconnected {
  background-color: rgb(198, 198, 198, 0.2);
  /* transition: all 0.5s ease; */
}

.connected .person:hover {
  cursor: pointer;
  background-color: rgb(168, 195, 232, 1);
  border-top: 1px solid rgb(50, 50, 50);
  border-bottom: 1px solid rgb(50, 50, 50);
  /* transition: all 0.5s ease; */
}

.connected .person.disconnected:hover {
  background-color: rgb(198, 198, 198, 0.3);
  border-top: 1px solid rgb(50, 50, 50);
  border-bottom: 1px solid rgb(50, 50, 50);
  /* transition: all 0.5s ease; */
}

.connected .person.disconnected#selected {
  background-color: rgb(198, 198, 198);
  border-top: 1px solid rgb(50, 50, 50);
  border-bottom: 1px solid rgb(50, 50, 50);
}

.connected .person#selected {
  cursor: pointer;
  background-color: rgb(168, 195, 232, 1);
  border-top: 1px solid rgb(50, 50, 50);
  border-bottom: 1px solid rgb(50, 50, 50);
  /* transition: all 0.5s ease; */
}

.missed .person {
  background-color: rgb(238, 192, 192);
  line-height: 2em;
  padding-right: 2rem;
  border-bottom: 1px solid rgb(136, 135, 135);
}

.person-name, .person-email, .missed-date {
  font-family: "Noto Sans JP", sans-serif;
  /* font-family: 'Robot', sans-serif; */
  font-weight: 300;
}

.dashboard-left h1 {
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  padding: 1rem;
  border-bottom: 3px solid rgb(189, 189, 189);
  border-top: 3px solid rgb(189, 189, 189);
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: white;
}

.dashboard-left h1:hover {
  cursor: pointer;
}

.no-selection img{
  width: 200px;
  display: block;
}

.dashboard-right {
  height: 100%;
  width: 80%;
  display: flex;
  flex-direction: column;
}

.no-selection {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: rgb(175, 173, 173);
  flex-direction: column;
}

.no-selection div {
  margin-top: 3rem;
}

.page-active {
  width: 40rem;
  height: 20rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px rgb(103, 103, 103);
  flex-direction: column;
  z-index: 999;
  border-radius: 8px;
  font-size: 1.5rem;
}

.page-active button {
  outline: none;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  background-color: rgb(187, 217, 249);
  margin-top: 1.5rem;
}

.page-active button:hover {
  color: white;
}

.dashboard-chat-container {
  position: relative;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 92%;
  overflow: hidden;
}

.chat-input {
  width: 100%;
  height: 8%;
  position: relative;
}

.chat-input img {
  position: absolute;
  top: -2.96rem;
  width: 6.5rem;
  left: 50%;
  transform: rotate(4deg) translate(-50%);
}

.chat-header {
  box-sizing: border-box;
  font-size: 2rem;
  color: rgb(60, 59, 59);
  border-bottom: 1px solid rgb(189, 187, 187);
  padding: 1rem 0;
  display: flex;
  justify-content: center;
  background-color: white;
  height: 12%;
}

.dashboard-chat-body {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  overflow: auto;
  padding-bottom: 1.5em;
  height: 88%;
  /* overflow:auto; */
}

.dashboard-chat-body > :first-child {
  margin-top: auto !important;
}

.is-online, .is-offline {
  position: absolute;
  right: 0;
  font-size: 0.9rem;
  padding: 0.5rem 1rem;
}

.is-online {
  color: rgb(76, 199, 76);
}

.is-offline {
  color: rgb(148, 144, 144);
}

.msg-text {
  padding: 0.5rem 0.8rem;
  font-size: 1rem;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 300;
}

.msg-container {
  margin: 0.5rem 0;
  width: fit-content;
  max-width: 45%;
}

.msg-container.recipient .msg-text {
  background-color: rgb(228, 228, 228, 0.7);
  width: fit-content;
  border-radius: 5px;
}

.msg-container.recipient .msg-info {
  display: flex;
  flex-direction: row-reverse;
  width: fit-content;
  padding: 3px 0;
}

.msg-container.sender .msg-text {
  background-color: rgba(200, 213, 231, 0.7);
  width: fit-content;
  border-radius: 5px;
  align-items: flex-end;
}

.msg-container.sender {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.msg-info span {
  width: fit-content;
  font-family: 'Roboto', sans-serif;
  font-size: 0.8rem;
  font-weight: 200;
  color: gray;
}

.system-msg {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  line-height: 1.5rem;
  padding: 1rem 0;
  /* border-bottom: 1px solid lightgray; */
  margin-bottom: 1rem;
}

.system-msg .dark {
  /* font-family: 'Ubuntu', sans-serif; */
}

.system-msg .light {
  font-style: italic;
  color: rgb(165, 165, 165);
}


.side-tools {
  display: flex;
  flex-direction: column;
  width: 20%;
  border: 1px solid black;
  position: relative;
}

.side-tools .tips {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  padding: 1rem 0;
  border-bottom: 2px solid lightgray;
  cursor: pointer;
}

.side-tools .tip-one-hidden {
  max-height: 0;
  overflow: hidden;
  margin: 1rem 0.8rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
  font-size: 0.8rem;
  /* background-color: transparent; */
  transition: max-height 0.5s ease-out;
}

.side-tools .tip-one {
  padding: 1rem 0.8rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
  font-size: 0.8rem;
  /* background-color: transparent; */
  max-height: 500px;
  transition: max-height 0.5s ease-in;
}

.side-tools .tips:hover {
  background-color: rgb(244, 244, 244);
}


#luffy {
  position: absolute;
  width: 10.5rem;
  bottom: 0;
  right: 50%;
  left: 50%;
  transform: translate(-50%);
  opacity: 0.3;
  -webkit-mask-image:-webkit-gradient(linear, bottom, top, from(rgba(0,0,0,1)), to(rgba(0,0,0,0.3)));
      mask-image: linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0.3));
  pointer-events: none;
}