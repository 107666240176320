.suitable-container .wrap, .not-suitable-container .wrap {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: auto;
  font-family: 'PT Sans', sans-serif;
  padding: 1rem 2rem;
  padding-bottom: 0;
  flex-wrap: wrap;
}

.suitable-container, .not-suitable-container {
  box-shadow: 0 0 0.5rem rgb(159, 159, 159);
  border-radius: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.suitable-container .wrap h1:before {
  position: absolute;
  content: 'YES';
  width: 100%;
  height: 100%;
  font-size: 4em;
  left: 0;
  top: -60%;
  color: rgba(167, 167, 167, 0.2);
}

.adhesive-not-suitable-page .suitable-container .wrap h1:before {
  position: absolute;
  content: 'NO';
  width: 100%;
  height: 100%;
  font-size: 4em;
  left: 0;
  top: -60%;
  color: rgba(167, 167, 167, 0.2);
}

.suitable-container .wrap h1 {
  position: relative;
  text-align: center;
  font-size: 2rem;
  padding: 1rem;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
}

.slider-container {
  position: relative;
  width: 24rem;
  height: 18rem;
  margin: 3rem 4vw;
  display: flex;
  align-items: center;
  overflow: hidden;

}

.backdrop {
  position: absolute;
  height: 18rem;
  width: 24rem;
  background-color: rgba(38, 65, 111, 0.5);
  left: 27rem;
  bottom: 5rem;
}

.slide{
  min-width: 100%;
  height: 100%;
  transition: 0.5s;
  overflow: hidden;
  position: relative;
}

%btn-styles {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 15%;
  height: 100%;
  background: none;
  text-decoration: none;
  border: none;
  outline: none;
  transition: 0.5s;
  &:hover {
    background: rgba(0, 0, 0, 0.35);
    cursor: pointer;
    color: whitesmoke;
  }
}

.wall-img {
  width: 100%;
  height: 100%;
}

#goLeft{
  left: 0;
  @extend %btn-styles;
}

#goRight{
  right: 0;
  @extend %btn-styles;
}

.image-list {
  display: flex;
  justify-content: center;
  margin-bottom: 5rem;
  width: auto;
}
.image-list li, .image-list li div {
  display: inline-block;
  text-align: center;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 400;
  font-size: 1.3rem;
}
.image-list li div {
  padding: 0.5vh 3vw;
  text-decoration: none;
  color:rgb(87, 87, 87);
  border-left: 2px solid rgb(88, 88, 88);
}
.image-list li div:hover {
  font-weight: 900;
  cursor: pointer;
  color: rgb(24, 24, 24);
  /* background-color: rgb(230, 230, 230, 0.6); */
}
.image-list li div::before {
  display: block;
  content: attr(title);
  font-weight: 900;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.image-list li:first-child div {
  border-left: none;
}

@media only screen and (max-width: 630px) {
  .image-list li div {
    font-size: 3.3vw;
    padding: 0.5vh 2vw;
    border-left: 1px solid black;
  }
  .slider-container {
    margin: 3rem 0;
  }
  .adhesive-not-suitable-page .suitable-container .wrap h1:before {
    top: -40%;
  }
}

@media only screen and (max-width: 1200px) {
  .suitable-container .wrap, .not-suitable-container .wrap {
    justify-content: center;
  }
}