.card-container {
  display: flex;
  flex-direction: column;
  min-width: 18.75em;
  position: relative;
  margin: 0 5vw 13vh 5vw;
  width: 18.75em;
}

.image-container{
  width: auto;
  height: 18.75em;
  overflow: hidden;
}
.product-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.caption { 
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0 0 20px 20px;
  padding: 10px 20px 20px 20px;
  border: 1px solid rgb(179, 177, 177, 0.6);
  border-top: none;
  height: 150px;
  flex-wrap: wrap;
  transition: box-shadow 0.3s ease;
}

.color-option {
  width: 100%;
  margin: 5px 0;
  padding-bottom: 4px;
  border-bottom: 1px solid rgb(168, 166, 166);
  display: flex;
  align-items: center;
}

.color-circle {
  display: inline-block;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  margin: 3px 3px;
}

.color-frame { 
  border: 1px solid transparent;
  padding: 0px;
  display: flex;
  align-items: center;
}

.color-frame:hover {
  border: 1px solid rgb(131, 131, 131);
  transition: border 0.3s ease;
}

.color-circle.black {
  background-color: rgb(1, 1, 1, 0.9);
}

.color-circle.silver {
  background-color: rgba(130, 130, 130, 0.9);
}

.color-circle.white {
  background-color: white;
  border: 1px solid rgb(85, 84, 84, 0.9);
}

.color-circle.silverUpgraded {
  background-color: rgba(70, 70, 70, 0.9);
  border: 1px solid rgb(58, 58, 58);
}

.color-circle.blue {
  background-color: rgb(139, 213, 241);
}

.color-circle.green {
  background-color: rgb(163, 228, 190);
}

.color-circle.pink {
  background-color: rgb(235, 198, 184);
}

.caption-text {
  background-color: white;
  color: rgb(31, 31, 31);
  /* padding: 23px 18px; */
  align-items: center;
  border-radius: 0 0 0 20px;
  max-width: 200px;
}

.caption-title {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: bold;
  font-size: 16px;
}

.caption-desc {
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 14px;
  padding-top: 5px;
  color: rgb(91, 91, 91);
  max-width: 180px;
}

.price {
  font-size: 18px;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 500;
}

.caption:hover {
    box-shadow: 0px 5px 3px 3px rgba(0, 0, 0, 0.15);
    transition: box-shadow 0.3s ease;
}

.image-container:hover .modal {
  opacity: 1;
  transition: opacity 0.5s ease;
  background-color: rgb(38, 38, 38, 0.5);
}

.image-container:hover .buy-now {
  visibility: visible;
}

.image-container:hover .product-image {
  transform: scale(1.3);
}

.modal {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 18.75em;
  transition: all 1s ease;
}

.buy-now {
  visibility: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-decoration: none;
  color: rgb(36, 36, 36);
  background-color: white;
  font-size: 25px;
  padding: 10px 20px;
  border-radius: 5px;
}

.buy-now:hover {
  color: white;
  background-color: rgb(36, 36, 36);
  transition: color 0.3s ease;
}


