.home-section{
  overflow: hidden;
}

section {
  display: flex;
  height: 80vh;
  justify-content: center;
  align-items: center;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  /* background: linear-gradient(to bottom, rgb(33, 33, 33), rgb(211, 211, 211)); */
  background: rgb(33, 33, 33);
  z-index: -1;
}

.hero {
  height: 80%;
  width: 85%;
  position: relative;
}

.hero img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.headline {
  position: absolute;
  top: 90%;
  left: 3%;
  font-size: 6em;
  transform: translate(-20%, -70%);
  color: white;
  z-index: 2;
  font-family: 'Tangerine', cursive;
  /* width: 30em; */
  /* width: 60%; */
  /* height: 20%; */
}

.hero::after{
  content: '';
  background: black;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.1;
}

.center-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-55%, -120%);
  text-decoration: none;
  color: white;
  font-size: 1.5em;
  border-radius: 8px;
  background-color: rgb(46, 46, 46);
  display: inline-block;
  width: 160px;
  padding: 10px 0px;
  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.25),
              -6px -6px 10px -1px rgb(255, 255, 255, 0.7);

}

.center-button-text{
  display: inline-block;
  margin-left: 40px;
  transition: 0.5s ease;
  transform: scale(1);
  /* border-bottom: 2px solid orange; */
}

.center-button-text:after{
  content: '';
  border-bottom: 2px solid orange;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 1px;
}

.amazon-icon {
  margin-left: 5px;
  position: absolute;
  top: 5px;
}

.right-arrow{
  opacity: 0;
  transition: opacity 0s ease 800ms, opacity 800ms;
  position: relative;
  font-size: 25px;
  font-weight: bold;
  transform: scale(1.8);
  color: rgb(238, 166, 11);
  left: 1.5rem;
}

.right-arrow:after{
  display: inline-block;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(46, 46, 46);
  transition: width 800ms;
  /* background: transparent; */
}

.center-button:hover .right-arrow {
  opacity: 1;
  transition: opacity 0s ease 800ms, opacity 800ms;
}

.center-button:hover .right-arrow:after{
  width: 0;   
}

.center-button:hover .center-button-text{
  margin-left: 25px; 
  transition: 0.3s ease;
  transform: scale(0.8);
}

.description {
  display: flex;
  background: rgb(28, 28, 28, 0.6);
  position: absolute;
  color: white;
  bottom: 0px;
  width: 40%;
  height: 29%;
  right: 0px;
  padding: 35px;
  padding-left: 50px;
  text-align: left;
  font-size: 100%;
}

.description:before{
  content: '';
  position: absolute;
  background-color: white;
  width: 2px;
  height: 55%;
  top: 30px;
  left: 25px;
}

/* social connects */

.connect {
  display: flex;
  position: absolute;
  bottom: 5%;
  align-items: center;
  height: auto;
  line-height: 2rem;
  justify-content: space-evenly;
  width: 50%;
}

.connect a {
  line-height: 2.3rem;
}

.connect .social-icon {
  font-size: 2rem;
  transition: 0.3s ease;
  color: rgb(187, 187, 187);
}

.live-chat-text {
  text-decoration: none;
  color: rgb(187, 187, 187);
}

@media (hover: hover) and (pointer: fine) {
  .connect .social-icon:hover{
    transform: scale(1.5);
    transition: 0.3s ease;
  }

  .social-icon.wechat:hover {
    color: #09b83e;
  }

  .social-icon.facebook:hover {
    color: #3b5999;
  }

  .social-icon.instagram:hover {
    color: #e4405f;
  }

  .social-icon.twitter:hover {
    color: #55acee;
  }

  .live-chat:hover .social-icon{
    color: rgb(96, 126, 203);
  }

  .connect .live-chat:hover .social-icon{
    transform: scale(1.5);
    transition: 0.3s ease;
  }

  .connect .live-chat:hover .live-chat-text{
    font-size: 1.1rem;
    transition: 0.3s ease;
    text-decoration: none;
    color: rgb(96, 126, 203);
  }
}

.live-chat .live-chat-text {
  margin: 0 8px;
  transition: 0.3s ease;
  text-decoration: none;
}

.connect .live-chat {
  font-size: 0.8rem;
  width: 9rem;
  text-decoration: none;
  transition: 0.3s ease;
}

.live-chat-link {
  text-decoration: none;
  color: white;
}

@media screen and (max-width: 1100px) {

  .headline {
    font-size: 3em;
  }

  .description{
    font-size: 12.5px;
  }
}

@media screen and (max-width: 768px) {

  .headline {
    font-size: 2.8em;
    width: 120px;
    left: 10%;
  }

  .description{
    padding: 15px;
  }

  .description:before{
    display: none;
  }

  .hero {
    width: 100% !important;
  }

  .connect {
    width: 100%;
    bottom: -10%;
  }

  .connect .live-chat {
    width: auto;
  }
}

@media screen and (max-height: 667px) {

  .headline {
    top: 380px;
    font-size: 2em;
  }

  .description {
    height: 38%;
  }
}

@media screen and (min-height: 1024px) {
  /* body{
    overflow: hidden;
  } */

  .headline {
    top: 85%;
    font-size: 3.3em;
    width: 40%;
    left: 15%;
  }

  .description {
    height: 23vh;
    font-size: 1.5vh;
  }

  .description:before{
    top: 13%;
    left: 5%;
  }
}

@media screen and (min-width: 1450px) {
  .description {
    height: 18vh;
    padding: 2% 1% 1% 3%;
  }

  .headline {
    left: 3%;
    font-size: 7em;
  }
}