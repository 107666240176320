* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
  /* overflow-x: hidden; */
}

html, body {
  max-width: 100vw;
}

.policy-container {
  padding: 10%;
  padding-top: 5%;
  color: rgb(36, 36, 36, 0.9);
}

.policy-container * {
  font-family: 'Nunito Sans', sans-serif;
}

.policy-container li {
  text-indent: 2rem;
}

.policy-title {
  background-color: rgb(73, 96, 189);
  color: white;
  width: 100%;
  height: 15vh;
  line-height: 15vh;
  font-size: 3.3rem;
  padding-left: 5%;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 500;
}

.policy-container h1 {
  padding: 1.6rem 0;
}

.policy-container h2 {
  padding: 1rem 0;
}

.policy-container h2 {
  padding: 0.7rem 0;
}

@media only screen and (max-width: 1024px) {
  .policy-title {
    text-align: center;
    font-size: 2rem;
  }
}