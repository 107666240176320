* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  --mutedtext: rgba(133, 132, 132, 0.55);
  --blue: rgb(53, 53, 237, 0.8);;
}

.contact-container {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.left {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 0;
  justify-content: space-between;
  position: relative;
}

.top {
  background-image: url("./small-office-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 70vh;
  position: relative;
  /* padding: 2rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  top: -6em;
  /* background-blend-mode: lighten; */
}

.title-wrap {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}

.title1 {
  animation: showup 7s infinite;
}

.title2 {
  width: 0px;
  animation: reveal 7s infinite;
}

.title2 span {
  margin-left: -310px;
  animation: slidein 7s infinite;
}

@keyframes showup{
  0% {opacity: 0;}
  40% {opacity: 1;}
  80% {opacity: 1;}
  100% {opacity: 0;}
}

@keyframes slidein {
  0% {margin-left: -800px;}
  20% {margin-left: -800px;}
  35% {margin-left: 0px;}
  100% {margin-left: 0px;}
}

@keyframes reveal {
  0% {opacity: 0; width: 0px;}
  20% {opacity: 1; width: 0px;}
  30% {width: 310px;}
  80% {opacity: 1;}
  100% {opacity: 0; width: 310px;}
}

.top .title1, .title2 span {
  font-size: 3.3rem;
  color: black;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
}

.top .sub-title {
  font-size: 1rem;
  color: white;
  font-family: 'Roboto', sans-serif;
  font-weight: 200;
  margin: 1rem 0;
  border-left: 1px solid white;
  border-right: 1px solid white;
  padding: 0 0.5rem;
}

.quick-link-title {
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
}

.blank-space {
  height: 28rem;
  width: 100%;
  position: relative;
  /* background-color: rgb(211, 211, 211);
  background-color: #e1edf7; */
  background-color: #e9f1f7;
}

.quick-links {
  position: absolute;
  top: -6.5rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.quick-links a {
  text-decoration: none;
}

.shortcut {
  width: 30em;
  height: 15em;
  margin: 3rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1), 0 4px 22px 0 rgba(0,0,0,0.2);
  transition: box-shadow 0.5s ease;
  transition: background-color 100ms linear;
  z-index: 3;
}

.shortcut .name {
  display: flex;
  vertical-align: center;
  justify-content: center;
  align-items: middle;
}

.shortcut .name span{
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
}

.shortcut p {
  margin-top: 1rem;
  font-family: 'Open Sans', sans-serif;
  color: rgba(99, 99, 99, 0.7);
  font-weight: 600;
}

.shortcut-icon {
  display: inline-block;
  font-size: 4rem;
}

.shortcut-icon.chatbox {
  font-size: 4rem;
  color: rgb(9, 161, 55);
}

.shortcut-icon.email {
  color: rgba(66, 133, 244);
  font-size: 4rem;
}

.shortcut-icon.amazon {
  font-size: 3.5rem;
  color: black;
}

.shortcut span {
  display: flex;
  font-size: 1.8rem;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem;
}

.amazon span {
  color: rgba(255, 153, 0)
}

.web-shortcut {
  width: 12rem;
}


@media (hover: hover) and (pointer: fine) {
  .shortcut:hover {
    /* background-color: rgb(247, 247, 247); */
    /* box-shadow: inset 1px 1px 10px 6px rgba(109, 109, 109, 0.1); */
    box-shadow: none;
    cursor: pointer;
    transition: box-shadow 0.5s ease;
    background-color: rgb(38, 38, 38, 0.8);
    color: white;
    transition: background-color 100ms linear;
  }

  .shortcut.amazon:hover .shortcut-icon.amazon {
    color: white;
  }

  .shortcut:hover p {
    color: white;
  }

  .shortcut.direct-email:hover .shortcut-icon.email {
    color: rgb(168, 193, 255);
  }

  .shortcut.live-chat:hover .shortcut-icon.chatbox {
    color: rgb(128, 222, 131);
  }

  .shortcut.web:hover img {
    filter: brightness(0) invert(1);
  }
}


.line {
  font-size: 2.8em;
  font-weight: 500;
  color: black;
  font-family: 'Ubuntu', sans-serif;
}

.contact-description {
  width: 100%;
  height: auto;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 0 25%;
  padding-bottom: 5rem;
}

.contact-headline {
  width: auto;
  font-size: 0.8em;
  color: black;
  /* animation: 1.6s slide-right; */
  opacity: 1;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 500;
  line-height: 200%;
  padding: 1rem;
  overflow-x: hidden;
  text-align: center;
  border-bottom: 1px solid rgb(97, 97, 97);
}

.contact-important{
  position: relative;
  text-indent: 0px;
  color: #258ae9;
  animation: 1.6s slide-left;
  font-size: 0.7em;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 500;
  padding: 1rem;
}

.email {
  display: inline-block;
  font-size: 0.8rem;
  font-weight: 900;
  text-decoration: underline;
  cursor: pointer;
  position: relative;
}

.hide-tooltip {
  opacity: 0 !important;
}

.copied-wrap {
  position: relative;
}

.tooltip {
  opacity: 1;
  transition: opacity 0.3s ease;
  position: absolute;
  margin: 0.7rem 0;
  background-color: rgba(233, 233, 233, 0.7);
  border-radius: 2px;
  padding: 0.6rem 0 !important;
  display: inline-block;
  text-decoration: none !important;
  color: rgb(38, 38, 38);
  font-size: 1rem !important;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  width: 100%;
  height: auto;
}

@keyframes slide-right {
  0%{
    transform: translateX(200%);
    opacity: 0;
  }

  100%{
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes slide-left {
  0%{
    transform: translateX(-200%);
    opacity: 0;
  }

  100%{
    transform: translateX(0%);
    opacity: 1;
  }
}

.contact {
  width: 100%;
  padding: 8% 18%;
  border-bottom: 3px solid rgb(216, 216, 216);
  /* background-color: rgb(211, 211, 211); */
  background-color: white;
}

.contact form {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 3rem 0;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  /* background-color: #eef3f7; */
}

.contact h2 {
  /* color: rgba(24,	95,	218); */
  color: rgb(36, 36, 36);
  font-weight: 0;
  font-size: 2em;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 3rem 0;
  display: block;
  width: 100%;
  text-align: center;
}

.contact h2:before {
  content: 'Contact Us';
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 2em;
  bottom: -0.9em;
  left: 0;
  color: rgba(167, 167, 167, 0.15);
}

.contact form .inputBox {
  position: relative;
  display: inline-block;
  margin: 2.5rem;
  width: 20em;
}


.contact form .inputBox input,
.contact form .inputBox textarea {
  position: relative;
  background: transparent;
  outline: none;
  border: none;
  width: 100%;
  color: rgb(34, 34, 34);
  font-size: 16px;
  border-bottom: 1px solid rgba(133, 132, 132, 0.9);
  padding: 0.2rem 0;
}

.contact form .inputBox.message {
  border: 2px solid rgba(133, 132, 132, 0.9);
  margin: 1rem 0;
}

.contact form .inputBox.message span {
  padding: 10px;
}

.contact form .inputBox textarea {
  height: 150px;
  border-bottom: none;
  padding: 10px;
}


.contact form .inputBox button[type="submit"] {
  /* background: rgb(108, 118, 228, 0.7); */
  background: #B7B5E4;
  padding: 8px 20px;
  cursor: pointer;
  color: white;
  font-size: 18px;
  font-weight: 400;
  border: none;
  text-transform: uppercase;
  letter-spacing: 4px;
  border-radius: 8px;
  width: 100%;
  outline: none;
  margin: 3rem 0;
}

.contact form .inputBox button[type="submit"]:hover {
  /* background: rgba(48, 63, 222, 0.9); */
  background: #706ae1;
  transition: 0.5s ease;
  display: block;
}

.submit-btn {
  margin: 0 !important;
  padding: 0;
  position: relative;
  top: -3rem;
}

.contact form .inputBox span {
  position: absolute;
  left: 0;
  font-size: 16px;
  font-weight: 300;
  display: inline-block;
  pointer-events: none;
  letter-spacing: 1px;
  color: var(--mutedtext);
  transition: all 0.2s;
}

.contact form .inputBox input:focus + span,
.contact form .inputBox input:not(:placeholder-shown) + span{
  transform: translateY(-26px);
  color: var(--blue);
  font-size: 18px;
}

.contact form .inputBox textarea:focus + span,
.contact form .inputBox textarea:not(:placeholder-shown) + span {
  transform: translateY(-42px);
  color: var(--blue);
  font-size: 18px;
}

textarea {
  resize: none;
  overflow-y: auto;
}

.fileup{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  margin-top: 1rem;
  margin-bottom: 1rem !important;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  position: relative;
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  /* margin: 60px 80px 30px 80px; */
  z-index: 10;
  width: auto;
  outline: none !important;
  text-decoration: none;
}


.drop-zone {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  top: 10px;
  margin: 0;
  outline: none !important;
}

.file-wrap{
  display: flex;
  justify-content: center;
  outline: none;
}

.file-name-container{
  overflow-y: auto;
  height: 100px;
  margin-top: 20px;
}

.file-name{
  margin-top: 10px;
  text-align: center;
  font-size: 13px;
}

::placeholder{
  color: transparent;
}


.status-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0.5rem;
}

.status.sent {
  color: rgb(62, 148, 62);
  font-size: 18px;
  text-align: center;
}

.status.error {
  color: rgb(167, 46, 46);
}

.fa-spin {
  font-size: 1.8em;
}

.status-container div{
  height: 30px;
  width: 300px;
}

/* .contact-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(100%)
} */

@media screen and (min-width: 1600px) { 
  .quick-links {
    padding: 0 15%;
  }
  .contact form .inputBox {
    width: 30em;
  }
  
}


@media screen and (max-width: 1152px) { 
  .blank-space{ 
    height: 90rem;
  }
}


@media screen and (max-width: 768px) { 
  .title1, .title2 span {
    font-size: 2rem !important;
  }

  .title2 span {
    margin-left: -185px;
  }

  .sub-title {
    font-size: 1rem !important;
  }
  
  @keyframes showup{
    0% {opacity: 0;}
    40% {opacity: 1;}
    80% {opacity: 1;}
    100% {opacity: 0;}
  }
  
  @keyframes slidein {
    0% {margin-left: -300px;}
    20% {margin-left: -300px;}
    35% {margin-left: 0px;}
    100% {margin-left: 0px;}
  }
  
  @keyframes reveal {
    0% {opacity: 0; width: 0px;}
    20% {opacity: 1; width: 0px;}
    30% {width: 185px;}
    80% {opacity: 1;}
    100% {opacity: 0; width: 185px;}
  }

  
  

  .contact-description {
    padding: 1rem 8%;
  }

  .blank-space{ 
    height: 75rem;
  }

  .contact {
    padding: 0;
  }

  .shortcut {
    margin: 0 2rem;
    margin-top: 3rem;
  }

  .shortcut p {
    /* margin-top: 1rem;
    font-family: 'Open Sans', sans-serif;
    color: rgba(99, 99, 99, 0.7);
    font-weight: 600; */
    font-size: 0.8rem;
    padding: 0 15px;
    text-align: center;
  }

  .quick-links {
    height: 75%;
  }
}


