.login-wrap{
  background-color: rgb(236, 244, 255);
  font-family: 'Open Sans', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 100px);
  margin: 0;
}

.login-container{
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.6);
  width: 30rem;
  max-width: 100%;
  height: 30rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 0 2rem;
  padding-bottom: 3rem;
}

.header{
  background-color: #f7f7f7;
  text-align: center;
  padding: 2rem 0;
  position: absolute;
  width: 100%;
  top: 0;
}

.form{
  padding: 10px 0;
  position: relative;
  width: 80%;
}

.formcontrol{
  margin-bottom: 10px;
  padding-bottom: 15px;
  position: relative;
}

.formcontrol label{
  display: inline-block;
  margin-bottom: 5px;
}

.formcontrol input{
  border: 2px solid #f0f0f0;
  border-radius: 4px;
  display: block;
  font-size: 12px;
  font-family: inherit;
  padding: 10px;
  width: 100%;
}

.formcontrol.success input{
  border-color: #2ecc71;
}

.formcontrol.error input{
  border-color: #e74c3c;
}

.formcontrol i {
  position: absolute;
  top: 40px;
  right: 10px;
  visibility: hidden;
}

.formcontrol.success .fa-check-circle{
  visibility: visible;
  color: #2ecc71;
}

.formcontrol.error .fa-exclamation-circle{
  visibility: visible;
  color: #e74c3c;
}

.formcontrol small{
  position: absolute;
  bottom: -3px;
  left: 0;
  visibility: hidden;
}

.formcontrol.error small{
  visibility: visible;
  color: #e74c3c;
}

.err-msg{
  color: #e74c3c;
  text-align: center;
}

.login-btn{
  color:rgba(59, 171, 72, 0.858);
  /* background-color: rgba(41, 179, 57, 0.858); */
  border: none;
  border-radius: 4px;
  display: block;
  padding: 10px;
  font-family: inherit;
  width: 100%;
  bottom: 50px;
  border: 2px solid  rgba(59, 171, 72, 0.858);
  background-color: white;
  margin-top: 3rem;
}

.login-btn:hover{
  background-color: rgba(59, 171, 72, 0.858);
  color: white;
}