
/* horizontal page */

.installation-wrap {
  /* width: auto; */
  height: 100vh;
  
}

.installation-container {
  box-sizing: border-box;
  /* width: auto; */
}

.installation-content{
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  background-color: #FAFAFA;
}

.installation-bg {
  width: 60vw;
  height: 100%;
  background-image: url("./installationbg2.jpg"); 
  background-repeat: no-repeat;
  background-size: cover;
  clip-path: polygon(0 0, 100% 0, 60% 100%, 0% 100%);
}

.video-instructions {
  width: 60vw;
  height: 100vh;
  display: flex;
  justify-content: left;
  color: black;
  align-items: center;
}

.video-instruction-text {
  mix-blend-mode: multiply;
  color: rgb(38, 38, 38);
  text-align: left;
  font-size: 3.5em;
  padding: 1.2rem 1.8rem;
  border-radius: 0.2em;
  font-family: 'Rubik', sans-serif;
  position: relative;
}

.video-instruction-text:before {
  content: 'WATCH';
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 2em;
  left: 10%;
  top: -30%;
  color: rgba(167, 167, 167, 0.15);
}

.video-instruction-subtext {
  font-size: 1rem;
  color: rgb(94, 94, 94);
}

.right-half {
  display: flex;
}

.video-gallery {
  display: grid;
  height: 100%;
  grid-template-columns: repeat(5, 16rem);
  grid-template-rows: 20rem 20rem;
  row-gap: 2rem;
  column-gap: 7rem;
  margin: auto;
  padding-left: 5em;
}

.box-color {
  opacity: 0.1;
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 100%;
  left: 0;
  transition: opacity 0.6s ease, transform 0.5s ease;
  border-radius: 5px;
}

.box-color1{
  background-color: #f2849e;
}
.box-color2{
  background-color: #7ecaf6;
}
.box-color3{
  background-color: #7bd0c1;
}
.box-color4{
  background-color: #c75b9b;
}
.box-color5{
  background-color: #ae85ca;
}
.box-color6{
  background-color: #8499e7;
}
.box-color7{
  background-color: #f2849e;
}
.box-color8{
  background-color: #7ecaf6;
}
.box-color9{
  background-color: #7bd0c1;
}

.video-box-wrapper {
  transition: background-color 0.5s ease, transform 0.5s ease;
  position: relative;
  overflow: hidden;
  margin: 0.8em 0;
}

@media (hover: hover) and (pointer: fine) {
  .video-box-wrapper:hover {
    transform: scale(1.1);
    cursor: pointer;
  }

  .video-box-wrapper:hover .box-color {
    opacity: 0;
    transition: opacity 0.5s ease, transform 0.5s ease;
  }

  .video-box-wrapper:hover #vid-thumbnail {
    opacity: 0.8;
    transition: opacity 0.5s ease, transform 0.5s ease;
  }
}

.video-gallery-title {
  color: rgb(89, 89, 89);
  font-size: 2.5rem;
  grid-area: title;
  text-align: center;
}

.video-box-wrapper {
  text-align: center;
  border-radius: 5px;
}

.video-box {
  position: absolute;
  width: 100%;
  height: 100%;
}

.video-box.hide-vid {
  left: -100%;
  transition: 1s;
}

.video-box.reveal-vid {
  left: 0;
  transition: 1s;
}


.video-gallery-subtitle {
  color: rgb(53, 53, 237, 0.8);
  font-size: 1.3rem;
}

#vid-thumbnail {
  width:100%; 
  height:100%;
  object-fit: cover;
  opacity: 0.5;
  transition: opacity 0.5s ease, transform 0.5s ease;
  border-radius: 5px;
}

.box-text {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 3;
  height: 100%;
  width: 100%;
  top: 0;
  color: white;
  transition: transform 0.5s ease;
  font-size: 1.2rem;
  padding: 0 10%;
}

.product-name {
  position: absolute;
  z-index: 3;
  height: auto;
  width: 100%;
  top: 50%;
  color: white;
  font-size: 1.25rem;
  padding: 0 10%;
  transition: transform 0.5s ease;
}

@media (hover: hover) and (pointer: fine) {
  .video-box:hover .product-name {
    transform: translateY(-80%);
    transition: transform 0.5s ease;
  }
  .video-box:hover .watch-text {
    opacity: 1;
    transform: translateY(-50%);
    transition: transform 0.7s ease;
  }
}

.watch-text {
  position: absolute;
  bottom: 0;
  width: fit-content;
  text-align: center;
  color: white;
  opacity: 0;
  font-size: 1.8rem;
  padding: 8px 16px;
  border-radius: 5px;
  background-color: rgb(26, 26, 26, 0.7);
  display: block;
  left: 0;
  right: 0;
  margin: auto;
}



.video_modal {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.adhesive-video {
  width: 100%;
  height: auto;
  text-decoration: none;
  outline: none;
}

.video-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65%;
  height: fit-content;
}

/* adhesive info */

.adhesive-container {
  display: flex;
  overflow: auto;
  position: relative;
}

.adhesive-glue-bg {
  background-image: url("./adhesive-glue-bg2.jpg");
  position: sticky !important;
  background-size: cover;
  width: 100vw;
  height: 100%;
  left: 0;
  z-index: 1;
  opacity: 0.8
}

.adhesive-intro-page {
  width: 100vw;
  height: 105vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.adhesive-box-wrap {
  position: relative;
  width: 60vw;
  height: 45vh;
  overflow: hidden;
  box-shadow: 0 0 0.5rem rgb(159, 159, 159);
  border-radius: 10px;
  background-color: white;
  z-index: 10;
}

.adhesive-title-box {
  display: flex;
  width: auto;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: absolute;
  top: 0;
  transition: 1s;
  opacity: 1;
  box-sizing: border-box;
}

.adhesive-title-box.hide {
  top: -100%;
  opacity: 0;
  transition: 3s;
}

.adhesive-title {
  font-size: 2.8vw;
  color: black;
  width: 50%;
  height: auto;
  word-wrap: break-word;
  white-space: pre-wrap;
  text-align: center;
  font-family: 'Rubik', sans-serif;
  padding: 0 10%;
  /* position: absolute; */
}

.adhesive-title-wrap{
  position: relative;
}

.adhesive-title-wrap:before {
  content: "GLUE";
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 2.3em;
  transform: translate(-50%, -100%);
  color: rgba(167, 167, 167, 0.3);
  z-index: -1;
}

.adhesive-intro {
  display: flex;
  width: 50%;
  height: auto;
  flex-wrap: wrap;
  word-wrap: break-word;
  white-space: pre-wrap;
  justify-content: center;
  align-items: center;
  font-size: 0.9vw;
  text-indent: 5em;
  line-height: 1.6em;
  font-family: 'Noto Sans JP', sans-serif;
  color: rgb(87, 74, 74);
  padding: 3em;
}

.adhesive-suitable-page, .adhesive-not-suitable-page {
  width: 100vw;
  height: 105vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

#hide-slider {
  opacity: 0;
  transition: 1s;
}

#show-slider {
  opacity: 1;
  transition: 1s;
}


/* get help section */

.help-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 105vh;
  background: linear-gradient(to bottom, rgb(245, 248, 250), #D2D7DF);
}

.product-specs-container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.specs-box {
  width: 20em;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  /* border: 1px solid red; */
}

.big-text {
  width: 100%;
  height: 100%;
  word-break: break-all;
  text-align: center;
  font-size: 1.3rem;
  padding: 0.5em;
  color: rgb(6, 6, 6, 0.8);
}

.small-text {
  width: 100%;
  height: 100%;
  word-wrap: break-word;
  white-space: pre-wrap;
  text-align: center;
  font-size: 0.9rem;
  color: rgb(6, 6, 6, 0.4);
}

.help-title {
  font-size: 3em;
  color: rgb(60, 60, 60);
  margin-top: 1em;
  margin-bottom: 1em;
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
}

.message-us {
  color: rgb(11, 43, 169);
  font-size: 0.6em;
  display: block;
  text-align: center;
  text-decoration: none;
}

.footer-pic {
  position: absolute;
  bottom: -100%;
  transform:scale(1.3)
}

.footer-pic.reveal {
  animation: showfooter 2s;
  bottom: 0;
  transition: 1.5s;
  transform:scale(1)
}

@keyframes showfooter {
  0% {opacity: 0;}
  30% {opacity: 1; transform:scale(1.3)}
  50% {opacity: 1; transform:scale(1.3)}
  70% {opacity: 1; transform:scale(1.3)};
  100% {opacity: 1; transform:scale(1)};
}

@media only screen and (max-width: 1024px) {

  .installation-content{
    display: block;
    position: relative;
  }

  .navbar-installation {
    background-color: rgb(33, 33, 33);
  }

  .navbar-installation .nav-links {
    color: white;
  }

  .navbar-installation .logo{
    filter: brightness(0) invert(1);
  }

  .installation-content {
    position: relative;
  }

  .installation-bg {
    width: 100% !important;
    clip-path: polygon(0 0, 100% 0, 0 100%, 0 100%);
  }

  .installation-content .video-instructions {
    width: auto;
    height: 100vh;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    align-items: flex-end;
    padding-bottom: 3em;
  }

  .video-instruction-text {
    mix-blend-mode: multiply;
    color: rgb(38, 38, 38);
    text-align: left;
    font-size: 2.8em;
    /* border: 3px solid rgb(20, 20, 20, 0.8); */
    padding: 1.2rem 1.8rem;
    border-radius: 0.2em;
    text-align: right;
  }

  .video-instruction-text:before {
    font-size: 1.8em;
    left: 0;
    top: -10%;
  }
  
  .video-instruction-subtext {
    font-size: 1rem;
    color: rgb(94, 94, 94);
    text-align: right;
  }
  
  .right-half {
    display: block;
  }
  
  .right-half .video-gallery {
    display: flex !important;
    flex-direction: column;
    position: relative;
    padding: 5rem 0;;
    justify-content: center;
    align-items: center;
  }
  
  .video-box-wrapper {
    transition: background-color 0.5s ease, transform 0.5s ease;
    position: relative;
    overflow: hidden;
    height: 60vh;
    width: 80vw;
  }

  .box-color {
    opacity: 0.2;
  }

  .video-box:hover .product-name {
    transform: translateY(-80%);
    transition: transform 0.5s ease;
  }

  .adhesive-video {
    width: 100%;
    height: auto;
    text-decoration: none;
    outline: none;
  }
  
  .video-wrapper {
    width: 90%;
  }
  
  /* adhesive info */
  
  .adhesive-container {
    /* display: flex; */
    display: block;
  }
  
  .adhesive-intro-page {
    width: 100vw;
    height: 105vh;
    /* background-color: red; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .adhesive-box-wrap {
    position: relative;
    width: 100%;
    overflow: hidden;
    width: 100vw;
    box-shadow: 0 0 0.5rem rgb(159, 159, 159);
    border-radius: 10px;
    height: 400px;
  }
  
  .adhesive-title-box {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: auto;
  }
  
  .adhesive-title-box.hide {
    top: -100%;
    opacity: 0;
    transition: 3s;
  }
  
  .adhesive-title {
    font-size: 7vw;
    width: 100%;
    margin-bottom: 1em;
  }
  
  .adhesive-intro {
    display: flex;
    width: 80%;
    height: auto;
    padding: 0%;
    font-size: 3vw;
    font-family: 'Noto Sans JP', sans-serif;
  }
  
  .adhesive-suitable-page {
    width: 100vw;
    height: 100vh;
    /* scroll-snap-align: start; */
  }
  
  .adhesive-not-suitable-page {
    width: 100vw;
    height: 100vh;
    /* scroll-snap-align: end; */
  }

  .suitable-container .wrap, .not-suitable-container .wrap {
    padding: 0 !important;
    padding-top: 2em !important;
  }
  
  /* get help section */
  
  .help-section {
    /* display: flex; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    height: 105vh;
    background: linear-gradient(to bottom, #e8f1f9, #d8eeff);
    position: relative;
  }
  
  .product-specs-container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .specs-box {
    width: 20em;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    /* border: 1px solid red; */
  }
  
  .big-text {
    font-size: 1.1rem;
  }
  
  .small-text {
    font-size: 0.8rem;
  }
  
  .help-title {
    font-size: 2em;
    margin-top: 3em;
    margin-bottom: 1em;
  }
  
  .message-us {
    color: rgb(11, 43, 169);
    font-size: 0.6em;
    display: block;
    text-align: center;
    text-decoration: none;
  }
  
  .footer-pic {
    position: absolute;
    bottom: 0;
    transform:scale(1);
    /* display: none; */
  }
  
  .footer-pic.reveal {
    /* display: block; */
    animation: none;
    transform:scale(1);
    /* width: 100vw; */
  }
}

@media only screen and (max-width: 1600px) {
  .video-gallery{
    margin-top: 15vh;
  }
}

@media only screen and (min-width: 1600px) {
  .adhesive-title {
    font-size: 2vw;
  }

  .adhesive-intro {
    font-size: 0.75vw;
  }
}